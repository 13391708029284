import {Component, OnDestroy, OnInit} from '@angular/core';
import {FestivalService} from '../shared/services/festival.service';
import {Subscription} from 'rxjs';
import {Event} from '../shared/models/event.model';
import {Festival} from '../shared/models/festival.model';

@Component({
  selector: 'app-closest-event',
  templateUrl: './closest-event.component.html',
  styleUrls: ['./closest-event.component.css']
})
export class ClosestEventComponent implements OnInit, OnDestroy {

  festival: Festival;
  closestEvent: Event;
  closestEventSubscription: Subscription;
  festivalSubscription: Subscription;

  constructor(private festivalService: FestivalService) {
  }

  ngOnInit(): void {
    this.festivalSubscription = this.festivalService.festivalsSubject.subscribe((festival: Festival) => {
      if (festival) {
        this.festival = festival;
        if (festival) {
          this.closestEventSubscription = this.festivalService.getClosestEvent(this.festival.festivalEnglishName).subscribe(event => {
            if (event) {
              this.closestEvent = event[0];
            }
          });
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.festivalSubscription.unsubscribe();
    if (this.closestEventSubscription) {
      this.closestEventSubscription.unsubscribe();
    }
  }

}
