import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {Festival} from '../shared/models/festival.model';
import {faVideo} from '@fortawesome/free-solid-svg-icons';
import {ActivatedRoute} from '@angular/router';
import {FestivalService} from '../shared/services/festival.service';
import {DataService} from '../shared/services/data.service';
import * as moment from 'moment';
import {Event} from '../shared/models/event.model';
import {Title} from '@angular/platform-browser';
import {AuthService} from '../auth/auth.service';
import {User} from '../shared/models/user.model';
import {Location} from '@angular/common';

@Component({
  selector: 'app-event-page',
  templateUrl: './event-page.component.html',
  styleUrls: ['./event-page.component.css']
})
export class EventPageComponent implements OnInit, OnDestroy {
  fragment: string | null = null;
  user: User;
  festival: Festival;
  userSubscription: Subscription;
  festivalSubscription: Subscription;
  event: Event;
  eventSubscription: Subscription;
  attendEventSubscription: Subscription;
  cancelAttendEventSubscription: Subscription;
  faVideo = faVideo;
  formattedDate;
  dayOfEvent;
  fromHour;
  untilHour;
  numberOfAttendeesPercent: number;
  numberOfRegisters = 1;

  constructor(
    private route: ActivatedRoute,
    private festivalService: FestivalService,
    private authService: AuthService,
    private dataService: DataService,
    private title: Title,
    private location: Location
  ) {
  }

  ngOnInit(): void {
    this.userSubscription = this.authService.user.subscribe(user => {
      if (user) {
        this.user = user;
      }
    });

    this.festivalSubscription = this.festivalService.festivalsSubject.subscribe(festival => {
      if (festival) {
        this.festival = festival;
        this.getEvent();
      }
    });
  }

  getEvent(): void {
    const eventID = this.route.snapshot.paramMap.get('eventID');
    this.eventSubscription = this.dataService.getEvent(this.festival.festivalEnglishName, eventID).subscribe((event: Event) => {
      if (event) {
        this.event = event[0];
        const title = `${this.event.initiativeDescription} | ${this.event.genre}`;
        this.title.setTitle(title);
        this.formattedDate = moment(this.event.date).format('DD/MM');
        this.dayOfEvent = moment(this.event.date).locale('he').format('dddd');
        this.fromHour = moment(this.event.hour, 'h:mm:ss A').format('HH:mm');
        this.untilHour = moment(this.event.hour, 'h:mm:ss A').add(this.event.duration, 'minutes').format('HH:mm');
        const numberOfAttendeesPercent = this.event.numberOfAttendees / this.event.totalNumberOfPeopleThatCanAttend * 100;
        this.numberOfAttendeesPercent = isNaN(numberOfAttendeesPercent) ? 0 : (numberOfAttendeesPercent > 100 ? 100 : numberOfAttendeesPercent);
      }
    });
  }

  getLink(link): string {
    if (link) {
      if (link.indexOf('https://') > -1) {
        return link;
      } else if (link.indexOf('http://') > -1) {
        return link;
      } else {
        return `http://${link}`;
      }
    }
  }

  attendEvent(): void {
    if (this.user) {
      const data = {
        eventID: this.event.eventID,
        userID: this.user.userID
      };
      if (!this.event.userIsAttendingEvent) {
        this.attendEventSubscription = this.dataService.onEventAttend(data).subscribe(res => {
          this.getEvent();
        });
      } else {
        this.cancelAttendEventSubscription = this.dataService.onCancelEventAttend(this.user.userID, this.event.eventID).subscribe(res => {
          this.getEvent();
        });
      }
    } else {
      this.authService.openLoginModal.next();
    }
  }

  goBack(): void {
    this.location.back();
  }

  onAddParticipant(): void {
    if (this.numberOfRegisters <= 3) {
      this.numberOfRegisters++;
    }
  }

  onReduceParticipant(): void {
    if (this.numberOfRegisters >= 2) {
      this.numberOfRegisters--;
    }
  }

  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
    if (this.festivalSubscription) {
      this.festivalSubscription.unsubscribe();
    }
    if (this.eventSubscription) {
      this.eventSubscription.unsubscribe();
    }
    if (this.attendEventSubscription) {
      this.attendEventSubscription.unsubscribe();
    }
    if (this.cancelAttendEventSubscription) {
      this.cancelAttendEventSubscription.unsubscribe();
    }
  }

}
