import {Component, OnDestroy, OnInit} from '@angular/core';
import {Festival} from '../shared/models/festival.model';
import {Subscription} from 'rxjs';
import {FestivalService} from '../shared/services/festival.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: [
    '../shared/styles/static-pages.css',
    './about.component.css'
  ]
})
export class AboutComponent implements OnInit, OnDestroy {

  festival: Festival;
  festivalSubscription: Subscription;

  constructor(
    private festivalService: FestivalService
  ) {
  }

  ngOnInit(): void {
    this.festivalSubscription = this.festivalService.festivalsSubject.subscribe(festival => {
      if (festival) {
        this.festival = festival;
      }
    });
  }

  ngOnDestroy(): void {
    this.festivalSubscription.unsubscribe();
  }

}
