import {
  AfterViewChecked,
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import {Festival} from '../shared/models/festival.model';
import {Subscription} from 'rxjs';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {FestivalService} from '../shared/services/festival.service';
import {Event} from '../shared/models/event.model';
import {DataService} from '../shared/services/data.service';
import {Category} from '../shared/models/category.model';
import * as moment from 'moment';
import {filter} from 'rxjs/operators';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-events-list',
  templateUrl: './events-list.component.html',
  styleUrls: ['./events-list.component.css']
})
export class EventsListComponent implements OnInit, AfterViewChecked, OnDestroy {

  festival: Festival;
  routerSubscription: Subscription;
  festivalSubscription: Subscription;
  events: Event[];
  eventsSubscription: Subscription;
  festivalDays = [];
  selectedCategory: Category;
  selectedCategorySubscription: Subscription;
  date: string;
  pageTitle: string;
  isScrollExecuted = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private festivalService: FestivalService,
    private dataService: DataService,
    private title: Title
  ) {
  }

  ngOnInit(): void {
    this.routerSubscription = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      if (event.urlAfterRedirects.indexOf('events-list') > -1) {
        // const day = moment(this.date).locale('he').format('dddd');
        this.setDateInfo();
        this.getEvents();
      }
    });
    this.__init();
  }

  ngAfterViewChecked(): void {
    if (this.events && this.events.length > 0 && !this.isScrollExecuted) {
      this.scrollToLastViewedEventInList();
    }
  }

  scrollToLastViewedEventInList(): void {
    const lastClickedEventID = localStorage.getItem('lastClickedEventID');

    if (lastClickedEventID && !this.isScrollExecuted) {
      setTimeout(() => {
        const element = document.getElementById(lastClickedEventID);
        if (element) {
          element.scrollIntoView({});
          this.isScrollExecuted = true;
          localStorage.removeItem('lastClickedEventID');
        }
      }, 100);
    }
  }

  private __init(): void {
    this.setDateInfo();
    this.getFestival();
    this.selectedCategorySubscription = this.dataService.categorySubject.subscribe(category => {
      this.selectedCategory = category;
      this.getEvents();
    });
  }

  setDateInfo(): void {
    this.date = this.route.snapshot.paramMap.get('day');
    const day = moment(this.date).locale('he').format('dddd');
    this.pageTitle = `אירועי יום ${day}`;
  }

  getFestival(): void {
    this.festivalSubscription = this.festivalService.festivalsSubject.subscribe(festival => {
      if (festival) {
        this.festival = festival;
        this.getEvents();
      }
    });
  }

  getEvents(): void {
    let categoryID;
    if (this.selectedCategory) {
      categoryID = this.selectedCategory.genreID;
    }
    this.eventsSubscription = this.festivalService.getEvents(null, this.date, categoryID).subscribe(events => {
      if (events) {
        this.events = events;
        this.title.setTitle(`${this.festival.name} - ${this.pageTitle}`);
        this.isScrollExecuted = false;
      }
    });
  }

  ngOnDestroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
    if (this.festivalSubscription) {
      this.festivalSubscription.unsubscribe();
    }
    if (this.eventsSubscription) {
      this.eventsSubscription.unsubscribe();
    }
    if (this.selectedCategorySubscription) {
      this.selectedCategorySubscription.unsubscribe();
    }
  }

}
