<app-navbar></app-navbar>
<div class="container-fluid mb-5">

  <app-festival-start-time-counter></app-festival-start-time-counter>

  <app-search-festival></app-search-festival>

  <app-festival-days-list></app-festival-days-list>
</div>
<app-footer-navbar></app-footer-navbar>
