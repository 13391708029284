<div class="d-flex justify-content-center">
  <form [formGroup]="selectForm">
    <label>
      <select class="form-control" formControlName="category" (change)="onSelect()" [(ngModel)]="selectedCategory">
        <option value="">כל הקטגוריות</option>
        <option *ngFor="let item of categories" [ngValue]="item">
          {{ item.title }}
        </option>
      </select>
    </label>
  </form>
</div>
