import {Component, OnDestroy, OnInit} from '@angular/core';
import {Festival} from '../shared/models/festival.model';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {Meta, Title} from '@angular/platform-browser';
import {FestivalService} from '../shared/services/festival.service';
import * as moment from 'moment';

@Component({
  selector: 'app-festival-start-time-counter',
  templateUrl: './festival-start-time-counter.component.html',
  styleUrls: [
    './festival-start-time-counter.component.css'
  ]
})
export class FestivalStartTimeCounterComponent implements OnInit, OnDestroy {

  festival: Festival;
  festivalSubscription: Subscription;
  timeUntilFestival: { days, hours, minutes, seconds };
  timeCounter;
  formattedFromDate;
  formattedUntilDate;
  isTodayBetweenFestivalDates: boolean;
  isTodayBeforeFestivalDates: boolean;

  constructor(
    private route: ActivatedRoute,
    private title: Title,
    private meta: Meta,
    private festivalService: FestivalService
  ) {
  }

  ngOnInit(): void {
    this.festivalSubscription = this.festivalService.festivalsSubject.subscribe(festival => {
      if (festival) {
        this.festival = festival;
        this.title.setTitle(this.festival.name);
        this.meta.updateTag({name: 'description', content: 'This is the description content'}); // add a description tag

        const festivalMonthNumber = moment(this.festival.fromDate).format('M');
        this.formattedFromDate = moment(this.festival.fromDate).format('D');
        this.formattedUntilDate = moment(this.festival.untilDate).format('D') + '.' + festivalMonthNumber;

        const currentDate = moment().format();
        this.isTodayBeforeFestivalDates = moment(currentDate).isBefore(this.festival.fromDate);
        this.isTodayBetweenFestivalDates = moment(currentDate).isBetween(this.festival.fromDate, this.festival.untilDate, undefined, '[]');
        if (this.isTodayBeforeFestivalDates) {
          this.startCounter();
        }
      }
    });
  }

  startCounter(): void {
    this.calculateDateDiff();
    this.timeCounter = setInterval(() => {
      this.calculateDateDiff();
    }, 1000);
  }

  calculateDateDiff(): void {
    const festivalDateString = new Date(this.festival.fromDate);

    const second = 1000;
    const minute = second * 60;
    const hour = minute * 60;
    const day = hour * 24;

    const now = new Date();
    // @ts-ignore
    const millisecondsDifferences = festivalDateString - now;
    if (millisecondsDifferences >= 0) {
      const days = Math.floor(millisecondsDifferences / day);
      const hours = Math.floor((millisecondsDifferences % day) / hour);
      const minutes = Math.floor((millisecondsDifferences % hour) / minute);
      const seconds = Math.floor((millisecondsDifferences % minute) / second);

      this.timeUntilFestival = {
        days,
        hours,
        minutes,
        seconds
      };
    } else {
      // date expired
    }
  }

  ngOnDestroy(): void {
    clearInterval(this.timeCounter);
    this.festivalSubscription.unsubscribe();
  }

}
