import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Festival} from '../models/festival.model';
import {BehaviorSubject, Observable} from 'rxjs';
import * as moment from 'moment';
import {Event} from '../models/event.model';
import {DOCUMENT} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class FestivalService {

  festival: Festival;
  festivalsSubject = new BehaviorSubject<Festival>(null);
  currentFestivalSubject = new BehaviorSubject<Festival>(null);
  festivalIsOverSubject = new BehaviorSubject<boolean>(null);

  constructor(
    private http: HttpClient,
    @Inject(DOCUMENT) private document: any,
  ) {

  }

  setFestivalFromURL(): void {
    // const domain = this.document.location.hostname;
    const domain = '2024';
    if (domain) {
      const subdomain = domain.split('.')[0];
      if (subdomain) {
        this.getFestival(subdomain);
      }
    }
  }

  getFestival(subdomain): void {
    this.http.get<Festival>(`${environment.apiURL}/festival?festival=${subdomain}`).subscribe(festival => {
      this.festival = festival;
      this.festivalsSubject.next(festival);
    });
  }

  checkIfFestivalIsOver(festival: Festival): any {
    const currentDate = moment().format();
    const isTodayBeforeFestivalDates = moment(currentDate).isBefore(festival.fromDate);
    const isTodayBetweenFestivalDates = moment(currentDate).isBetween(festival.fromDate, festival.untilDate, undefined, '[]');
    const festivalIsOver = !isTodayBeforeFestivalDates && !isTodayBetweenFestivalDates;
    this.festivalIsOverSubject.next(festivalIsOver);
  }

  getClosestEvent(festivalEnglishName): Observable<Event[]> {
    return this.http.get<Event[]>(`${environment.apiURL}/event/closest?festival=${festivalEnglishName}`);
  }

  getEvents(search = null, date = null, category = null): Observable<Event[]> {
    let url = `${environment.apiURL}/event?festival=${this.festival.festivalEnglishName}`;
    if (search) {
      url += `&search=${search}`;
    }
    if (date) {
      url += `&date=${date}`;
    }
    if (category) {
      url += `&category=${category}`;
    }
    return this.http.get<Event[]>(url);
  }

  getMyEvents(search = null, date = null, category = null): Observable<Event[]> {
    let url = `${environment.apiURL}/attendee?festival=${this.festival.festivalEnglishName}`;
    if (search) {
      url += `&search=${search}`;
    }
    if (date) {
      url += `&date=${date}`;
    }
    if (category) {
      url += `&category=${category}`;
    }
    return this.http.get<Event[]>(url);
  }

  getFestivalDays(): any[] {
    const fromDate = moment(this.festival.fromDate, 'YYYY-MM-DD');
    const untilDate = moment(this.festival.untilDate, 'YYYY-MM-DD');

    // difference in number of days
    const numberOfDays = moment.duration(untilDate.diff(fromDate)).asDays();
    const festivalDays = [];
    for (let i = 0; i <= numberOfDays; i++) {
      festivalDays.push({
        day: moment(this.festival.fromDate).add(i, 'd').locale('he').format('dddd'),
        date: moment(this.festival.fromDate).add(i, 'd').format('YYYY-MM-DD')
      });
    }
    return festivalDays;
  }

}
