<div class="container mb-5">
  <div class="pt-4 text-center font-size-21">לא נורא, קורה לכולם</div>
  <div class="text-center 17">מייד נשלח אליכם מייל עם הוראות לאיפוס הסיסמא</div>
  <div class="text-center" *ngIf="isLoading">
    <app-loading-spinner></app-loading-spinner>
  </div>
  <form class="pt-5" [formGroup]="forgotPasswordForm" (ngSubmit)="onForgotPassword()" *ngIf="!isLoading">
    <div class="form-group">
      <label class="mb-4">
        <input type="email" formControlName="email" class="form-control" placeholder="אימייל" autocomplete="off">
      </label>
    </div>
    <div class="text-center">
      <button class="btn btn-submit w-100 font-size-22 font-weight-500" type="submit" [disabled]="forgotPasswordForm.invalid">שליחה</button>
    </div>
    <div class="mt-3 alert alert-danger" *ngIf="errorMessage">{{errorMessage}}</div>
    <div class="mt-3 alert alert-success" *ngIf="message">{{message}}</div>
  </form>
</div>
