<div class="mb-5">
  <app-navbar></app-navbar>
  <app-day-title title="{{pageTitle}}"></app-day-title>
  <div class="container-fluid">
    <div class="row">
      <div id="{{item.eventID}}" class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4" *ngFor="let item of events">
        <app-event-box [event]="item"></app-event-box>
      </div>
    </div>
  </div>
</div>
<app-footer-navbar *ngIf="events"></app-footer-navbar>
