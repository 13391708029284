<nav class="navbar" *ngIf="festival">
  <!--mobile-->
  <div class="w-100 d-flex d-md-none justify-content-space-between">
    <div class="d-flex social-networks-box">
      <a href="{{festival.facebook}}" target="_blank">
        <img src="assets/facebook.svg" alt="אייקון של פייסבוק">
      </a>
      <a href="{{festival.instagram}}" target="_blank">
        <img src="assets/instagram.svg" alt="אייקון של אינסטגרם">
      </a>
    </div>
    <div>
      <a href="https://shop.bringthemhomenow.net/" target="_blank">
        <img src="assets/Yellow_ribbon_1.svg" alt="אייקון של תמיכה בשחרור החטופים">
      </a>
    </div>
  </div>

  <!--desktop-->
  <div class="container-fluid d-none d-lg-flex justify-content-space-between">
    <div class="d-flex w-100 mb-3">
      <div class="col-12">
        <div class="d-flex justify-content-center">
          <a class="ml-3" href="{{festival.facebook}}" target="_blank">
            <img src="assets/facebook.svg" alt="אייקון של פייסבוק">
          </a>
          <a class="ml-3" href="{{festival.instagram}}" target="_blank">
            <img src="assets/instagram.svg" alt="אייקון של אינסטגרם">
          </a>
          <div>
            <a href="https://shop.bringthemhomenow.net/" target="_blank">
              <img src="assets/Yellow_ribbon_1.svg" alt="אייקון של תמיכה בשחרור החטופים">
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>
