<app-navbar></app-navbar>
<div class="container-fluid mb-5">
  <app-day-title title="כל האירועים"></app-day-title>

  <app-categories-select></app-categories-select>

  <div class="row">
    <div class="col-sm-12 col-md-2" *ngFor="let item of events">
      <app-event-box [event]="item"></app-event-box>
    </div>
  </div>
</div>
<app-footer-navbar *ngIf="events"></app-footer-navbar>
