import {Component, OnDestroy, OnInit} from '@angular/core';
import {Festival} from '../shared/models/festival.model';
import {Subscription} from 'rxjs';
import {Event} from '../shared/models/event.model';
import {Category} from '../shared/models/category.model';
import {ActivatedRoute} from '@angular/router';
import {FestivalService} from '../shared/services/festival.service';
import {DataService} from '../shared/services/data.service';
import * as moment from 'moment';
import {User} from '../shared/models/user.model';
import {AuthService} from '../auth/auth.service';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-my-events-list',
  templateUrl: './my-events-list.component.html',
  styleUrls: ['./my-events-list.component.css']
})
export class MyEventsListComponent implements OnInit, OnDestroy {

  user: User;
  festival: Festival;
  userSubscription: Subscription;
  festivalSubscription: Subscription;
  events: Event[];
  eventsSubscription: Subscription;
  festivalDays = [];
  selectedCategory: Category;
  selectedCategorySubscription: Subscription;
  date: string;
  pageTitle: string;

  constructor(
    private route: ActivatedRoute,
    private festivalService: FestivalService,
    private dataService: DataService,
    private authService: AuthService,
    private title: Title
  ) {
  }

  ngOnInit(): void {
    this.date = this.route.snapshot.paramMap.get('day');
    this.pageTitle = `האירועים שלי`;
    this.title.setTitle(this.pageTitle);

    this.userSubscription = this.authService.user.subscribe(user => {
      this.user = user;
      this.festivalSubscription = this.festivalService.festivalsSubject.subscribe(festival => {
        if (festival) {
          this.festival = festival;
          this.getMyEvents();
        }
      });
      this.selectedCategorySubscription = this.dataService.categorySubject.subscribe(category => {
        this.selectedCategory = category;
        this.getMyEvents();
      });
    });
  }

  getMyEvents(): void {
    let categoryID;
    if (this.selectedCategory) {
      categoryID = this.selectedCategory.genreID;
    }
    this.eventsSubscription = this.festivalService.getMyEvents(null, null, categoryID).subscribe(events => {
      if (events) {
        this.events = events;
      }
    });
  }

  ngOnDestroy(): void {
    this.festivalSubscription.unsubscribe();
    if (this.eventsSubscription) {
      this.eventsSubscription.unsubscribe();
    }
    if (this.selectedCategorySubscription) {
      this.selectedCategorySubscription.unsubscribe();
    }
  }

}
