import {Component, OnDestroy, OnInit} from '@angular/core';
import {User} from '../shared/models/user.model';
import {Subscription} from 'rxjs';
import {Festival} from '../shared/models/festival.model';
import {faTimesCircle, faSignOutAlt} from '@fortawesome/free-solid-svg-icons';
import {FestivalService} from '../shared/services/festival.service';
import {Router} from '@angular/router';
import {AuthService} from '../auth/auth.service';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-desktop-navbar-menu',
  templateUrl: './desktop-navbar-menu.component.html',
  styleUrls: ['./desktop-navbar-menu.component.css']
})
export class DesktopNavbarMenuComponent implements OnInit, OnDestroy {
  user: User;
  userSubscription: Subscription;
  festival: Festival;
  festivalSubscription: Subscription;
  festivalIsOverSubscription: Subscription;
  isCollapsed = true;
  faTimesCircle = faTimesCircle;
  faSignOutAlt = faSignOutAlt;
  festivalIsOver: boolean;
  searchForm: FormGroup;
  search;
  festivalDays: any[];
  closeResult = '';

  constructor(
    private festivalService: FestivalService,
    private authService: AuthService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.userSubscription = this.authService.user.subscribe(user => {
      this.user = user;
    });

    this.festivalSubscription = this.festivalService.festivalsSubject.subscribe(festival => {
      if (festival) {
        this.buildDays();
        this.festival = festival;
        this.festivalService.checkIfFestivalIsOver(festival);
        this.festivalIsOverSubscription = this.festivalService.festivalIsOverSubject.subscribe((isOver: boolean) => {
          this.festivalIsOver = isOver;
        });
      }
    });

    this.searchForm = new FormGroup({
      search: new FormControl('')
    });
  }

  onSearch(): void {
    const search = this.searchForm.controls.search.value;
    this.router.navigate([`/search-results`, {search}]);
    this.closeNavbar();
  }

  buildDays(): void {
    this.festivalDays = this.festivalService.getFestivalDays();
  }

  logout(): void {
    localStorage.removeItem('userData');
    this.router.navigate([`/`]);
    window.location.reload();
  }

  openLoginModal(): void {
    this.authService.openLoginModal.next();
  }

  closeNavbar(): void {
    this.isCollapsed = !this.isCollapsed;
  }

  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
    this.festivalSubscription.unsubscribe();
    this.festivalIsOverSubscription.unsubscribe();
  }

}
