<div class="row time-until-festival-box justify-content-center mt-2" *ngIf="festival && isTodayBeforeFestivalDates">
  <div class="row time-until-festival align-items-center mt-3" *ngIf="timeUntilFestival">
    <div class="col text-center seconds pr-5">
      <div class="time-number">{{timeUntilFestival.seconds}}</div>
      <div>שניות</div>
    </div>
    <div class="col text-center minutes">
      <div class="time-number">{{timeUntilFestival.minutes}}</div>
      <div>דקות</div>
    </div>
    <div class="col text-center hours">
      <div class="time-number">{{timeUntilFestival.hours}}</div>
      <div>שעות</div>
    </div>
    <div class="col text-center days pl-5">
      <div class="time-number">{{timeUntilFestival.days}}</div>
      <div>ימים</div>
    </div>
  </div>
</div>
