import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {MainComponent} from './main/main.component';
import {EventsListComponent} from './events-list/events-list.component';
import {EventPageComponent} from './event-page/event-page.component';
import {AllEventsListComponent} from './all-events-list/all-events-list.component';
import {SearchResultsComponent} from './search-results/search-results.component';
import {TermsComponent} from './terms/terms.component';
import {ResetPasswordComponent} from './reset-password/reset-password.component';
import {MyEventsListComponent} from './my-events-list/my-events-list.component';
import {AboutComponent} from './about/about.component';
import {NavigationComponent} from './navigation/navigation.component';
import {TeamComponent} from './team/team.component';
import {ThanksComponent} from './thanks/thanks.component';
import {SleepComponent} from './sleep/sleep.component';
import {UpdatesComponent} from './updates/updates.component';

const routes: Routes = [
  {path: '', component: MainComponent},
  {path: 'events-list/:day', component: EventsListComponent},
  {path: 'all-events-list', component: AllEventsListComponent},
  {path: 'my-events-list', component: MyEventsListComponent},
  {path: 'search-results', component: SearchResultsComponent},
  {path: 'event-page/:eventID', component: EventPageComponent},
  {path: 'about', component: AboutComponent},
  {path: 'navigation', component: NavigationComponent},
  {path: 'team', component: TeamComponent},
  {path: 'thanks', component: ThanksComponent},
  {path: 'sleep', component: SleepComponent},
  {path: 'updates', component: UpdatesComponent},
  {path: 'terms', component: TermsComponent},
  {path: 'reset-password', component: ResetPasswordComponent},
  {path: '**', redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
