import {Component, OnDestroy, OnInit} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {FestivalService} from '../shared/services/festival.service';
import {Subscription} from 'rxjs';
import {Festival} from '../shared/models/festival.model';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit, OnDestroy {

  festival: Festival;
  festivalSubscription: Subscription;

  constructor(
    private title: Title,
    private meta: Meta,
    private festivalService: FestivalService
  ) {
  }

  ngOnInit(): void {
    this.festivalSubscription = this.festivalService.festivalsSubject.subscribe(festival => {
      if (festival) {
        this.festival = festival;
        this.title.setTitle(this.festival.name);
        this.meta.updateTag({name: 'description', content: 'This is the description content'}); // add a description tag
      }
    });
  }

  ngOnDestroy(): void {
    this.festivalSubscription.unsubscribe();
  }

}
