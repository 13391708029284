import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {NavbarComponent} from './navbar/navbar.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LoadingSpinnerComponent} from './shared/components/loading-spinner/loading-spinner.component';
import {AuthService} from './auth/auth.service';
import {AuthGuardService} from './auth/auth-guard.service';
import {AuthInterceptorService} from './auth/auth-interceptor.service';
import {MainComponent} from './main/main.component';
import {FooterNavbarComponent} from './footer-navbar/footer-navbar.component';
import {ClosestEventComponent} from './closest-event/closest-event.component';
import {FestivalDaysListComponent} from './festival-days-list/festival-days-list.component';
import {FestivalStartTimeCounterComponent} from './festival-start-time-counter/festival-start-time-counter.component';
import {SearchFestivalComponent} from './search-festival/search-festival.component';
import {EventsListComponent} from './events-list/events-list.component';
import {EventBoxComponent} from './event-box/event-box.component';
import {DayTitleComponent} from './day-title/day-title.component';
import {EventPageComponent} from './event-page/event-page.component';
import {CategoriesSelectComponent} from './categories-select/categories-select.component';
import {AllEventsListComponent} from './all-events-list/all-events-list.component';
import {SearchResultsComponent} from './search-results/search-results.component';
import {LoginComponent} from './login/login.component';
import {RegisterComponent} from './register/register.component';
import {TermsComponent} from './terms/terms.component';
import {ResetPasswordComponent} from './reset-password/reset-password.component';
import {MyEventsListComponent} from './my-events-list/my-events-list.component';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {AboutComponent} from './about/about.component';
import {NavigationComponent} from './navigation/navigation.component';
import {TeamComponent} from './team/team.component';
import {ThanksComponent} from './thanks/thanks.component';
import {SleepComponent} from './sleep/sleep.component';
import {UpdatesComponent} from './updates/updates.component';
import {DesktopNavbarMenuComponent} from './desktop-navbar-menu/desktop-navbar-menu.component';
import {MobileNavbarMenuComponent} from './mobile-navbar-menu/mobile-navbar-menu.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    LoadingSpinnerComponent,
    MainComponent,
    FooterNavbarComponent,
    ClosestEventComponent,
    FestivalDaysListComponent,
    FestivalStartTimeCounterComponent,
    SearchFestivalComponent,
    EventsListComponent,
    EventBoxComponent,
    DayTitleComponent,
    EventPageComponent,
    CategoriesSelectComponent,
    AllEventsListComponent,
    SearchResultsComponent,
    LoginComponent,
    RegisterComponent,
    TermsComponent,
    ResetPasswordComponent,
    MyEventsListComponent,
    ForgotPasswordComponent,
    AboutComponent,
    NavigationComponent,
    TeamComponent,
    ThanksComponent,
    SleepComponent,
    UpdatesComponent,
    DesktopNavbarMenuComponent,
    MobileNavbarMenuComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    HttpClientModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    FontAwesomeModule,
    FontAwesomeModule,
    NgbModule
  ],
  providers: [
    AuthService,
    AuthGuardService, {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
