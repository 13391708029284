import {Component, OnDestroy, OnInit} from '@angular/core';
import {Festival} from '../shared/models/festival.model';
import {Subscription} from 'rxjs';
import {FestivalService} from '../shared/services/festival.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit, OnDestroy {

  festival: Festival;
  isCollapsed = true;
  festivalSubscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private festivalService: FestivalService,
  ) {
  }

  ngOnInit(): void {
    this.festivalSubscription = this.festivalService.festivalsSubject.subscribe(festival => {
      this.festival = festival;
    });
  }

  ngOnDestroy(): void {
    this.festivalSubscription.unsubscribe();
  }
}
