import {AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AuthService} from '../auth/auth.service';
import {DataService} from '../shared/services/data.service';
import {City} from '../shared/models/city.model';
import {Subscription} from 'rxjs';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: [
    '../shared/styles/forms-style.css',
    './register.component.css'
  ]
})
export class RegisterComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('registerModal') registerModal: TemplateRef<any>;

  registerForm: FormGroup;
  requestInProgress: boolean;
  isLoading: boolean;
  formSubmitted: boolean;
  error: boolean;
  serverMessage: string;
  cities: City[];
  openModalSubscription: Subscription;
  modalReference;
  acceptTerms: boolean;

  constructor(
    public router: Router,
    private authService: AuthService,
    private dataService: DataService,
    private modalService: NgbModal
  ) {
  }

  ngOnInit(): void {
    this.registerForm = new FormGroup({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required]),
      phone: new FormControl('', [Validators.required])
    });

    this.openModalSubscription = this.authService.openRegisterModal.subscribe(() => {
      this.open();
    });
  }

  open(): void {
    this.modalReference = this.modalService.open(this.registerModal, {ariaLabelledBy: 'modal-basic-title'});
  }

  submitRegister(): void {
    this.formSubmitted = true;
    this.error = false;
    this.serverMessage = null;
    if (this.registerForm.valid && this.acceptTerms) {
      this.isLoading = true;
      this.requestInProgress = true;
      this.authService.onRegister(this.registerForm.value).subscribe(res => {
        this.isLoading = false;
        this.requestInProgress = false;
        this.serverMessage = res.message;
        if (parseInt(res.status, 10) === 1) {
          setTimeout(() => {
            this.closeModal();
            this.router.navigate([this.authService.onSuccessRouterTarget]);
          }, 2000);
        } else {
          this.error = true;
        }
      }, err => {
        this.error = true;
        this.serverMessage = err.message;
      });
    }
  }

  closeModal(): void {
    if (this.modalReference) {
      this.modalReference.close();
    }
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
    this.openModalSubscription.unsubscribe();
  }
}
