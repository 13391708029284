<div class="d-flex page-navbar justify-content-start" *ngIf="festival">
  <div class="col-2">
    <img class="logo pointer" src="https://api.hanadiv.org/images/{{festival.logo}}" alt="לוגו פסטיבל" routerLink="/">
  </div>
  <div class="col-8">
    <div class="d-none d-md-block">
      <app-desktop-navbar-menu></app-desktop-navbar-menu>
    </div>
  </div>
  <div class="d-block d-md-none">
    <app-mobile-navbar-menu></app-mobile-navbar-menu>
  </div>
  <div class="col-2"></div>
</div>
