import {Component, OnDestroy, OnInit} from '@angular/core';
import {Festival} from '../shared/models/festival.model';
import {Subscription} from 'rxjs';
import {Event} from '../shared/models/event.model';
import {Category} from '../shared/models/category.model';
import {ActivatedRoute} from '@angular/router';
import {FestivalService} from '../shared/services/festival.service';
import {DataService} from '../shared/services/data.service';
import * as moment from 'moment';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.css']
})
export class SearchResultsComponent implements OnInit, OnDestroy {

  festival: Festival;
  festivalSubscription: Subscription;
  events: Event[];
  eventsSubscription: Subscription;
  festivalDays = [];
  selectedCategory: Category;
  selectedCategorySubscription: Subscription;
  routeParamsSubscription: Subscription;
  search: string;
  date: string;
  pageTitle: string;

  constructor(
    private route: ActivatedRoute,
    private festivalService: FestivalService,
    private dataService: DataService,
    private title: Title,
  ) {
  }

  ngOnInit(): void {
    this.title.setTitle('תוצאות חיפוש אירוע');
    this.festivalSubscription = this.festivalService.festivalsSubject.subscribe(festival => {
      if (festival) {
        this.festival = festival;

        // subscribe to route params so on every change in search param the query will run (in case navbar search when user in current page it's mandatory)
        this.routeParamsSubscription = this.route.params.subscribe((params) => {
          this.getEvents();
        });
      }
    });
    this.selectedCategorySubscription = this.dataService.categorySubject.subscribe(category => {
      this.selectedCategory = category;
      this.getEvents();
    });
  }

  getEvents(): void {
    let categoryID;
    if (this.selectedCategory) {
      categoryID = this.selectedCategory.genreID;
    }
    this.search = this.route.snapshot.paramMap.get('search');
    this.date = this.route.snapshot.paramMap.get('day');
    this.eventsSubscription = this.festivalService.getEvents(this.search, this.date, categoryID).subscribe(events => {
      if (events) {
        this.events = events;
      }
    });
  }

  ngOnDestroy(): void {
    this.festivalSubscription.unsubscribe();
    if (this.eventsSubscription) {
      this.eventsSubscription.unsubscribe();
    }
    if (this.selectedCategorySubscription) {
      this.selectedCategorySubscription.unsubscribe();
    }
    if (this.routeParamsSubscription) {
      this.routeParamsSubscription.unsubscribe();
    }
  }

}
