<ng-template #loginModal let-modal>
  <div class="container p-4">
    <div class="pt-4 text-center font-size-20">התחבר/י על מנת להרשם לאירוע</div>
    <div class="text-center" *ngIf="isLoading">
      <app-loading-spinner></app-loading-spinner>
    </div>
    <form class="pt-5" [formGroup]="loginForm" (ngSubmit)="onLogin()" *ngIf="!isLoading">
      <div class="form-group">
        <label class="mb-4">
          <input type="email" formControlName="email" class="form-control" placeholder="אימייל" autocomplete="off">
        </label>
        <label>
          <input type="password" class="form-control" formControlName="password" placeholder="סיסמא">
        </label>
      </div>
      <div class="text-center">
        <button class="btn btn-submit login-btn font-weight-500" type="submit" [disabled]="loginForm.invalid">כניסה</button>
      </div>
      <div class="mt-3 alert alert-danger" *ngIf="errorMessage">{{errorMessage}}</div>
      <div class="mt-3 alert alert-success" *ngIf="message">{{message}}</div>
    </form>
    <div class="font-size-17 mt-4 forgot-password" (click)="openForgotPasswordModal(forgotPasswordModal)" *ngIf="!isLoading">שכחת סיסמא?</div>
    <!--    <div class="font-size-21">או</div>
        <div class="font-weight-500 font-size-21">התחברות דרך</div>
        <div class="mt-2">
          <img src="assets/facebook_blue.svg" alt="אייקון של פייסבוק" (click)="loginWithFacebook()">
          <img class="mr-4" src="assets/google.svg" alt="אייקון של גוגל" (click)="loginWithGoogle()">
        </div>-->
    <div class="mt-5 mb-5 d-flex justify-content-center">
      <div class="no-account-yet" (click)="closeModal(); openRegisterModal()">
        עדיין אין לך חשבון?
      </div>
    </div>
  </div>
</ng-template>

<ng-template #forgotPasswordModal let-modal>
  <app-forgot-password></app-forgot-password>
</ng-template>
