<ng-template #registerModal let-modal>
  <div class="container-fluid vh-100 mb-5 p-4">
    <div class="row page-navbar">
      <span class="col-12 text-center font-size-20">הרשמה</span>
    </div>
    <div class="text-center" *ngIf="isLoading">
      <app-loading-spinner></app-loading-spinner>
    </div>
    <div class="row mb-3" *ngIf="!isLoading">
      <div class="col-12 mt-3 intro-title font-size-16">במקום בו הלב רחב, גם הקירות מתרחבים</div>
    </div>
    <form [formGroup]="registerForm" (ngSubmit)="submitRegister()" *ngIf="!isLoading">
      <div class="form-group">
        <label>
          <input type="text" formControlName="firstName" class="form-control" placeholder="שם פרטי">
        </label>
        <div class="color-red mb-1" *ngIf="formSubmitted && !registerForm.controls.firstName.valid">נא להזין שם פרטי</div>
        <label>
          <input type="text" formControlName="lastName" class="form-control" placeholder="שם משפחה">
        </label>
        <div class="color-red mb-1" *ngIf="formSubmitted && !registerForm.controls.lastName.valid">נא להזין שם משפחה</div>
        <label>
          <input type="email" formControlName="email" class="form-control" placeholder="דואר אלקטרוני" autocomplete="off">
        </label>
        <div class="color-red mb-1" *ngIf="formSubmitted && !registerForm.controls.email.valid">נא להזין דואר אלקטרוני</div>
        <label>
          <input type="text" class="form-control" formControlName="phone" placeholder="נייד">
        </label>
        <div class="color-red mb-1" *ngIf="formSubmitted && !registerForm.controls.phone.valid">נא להזין נייד</div>
        <label>
          <input type="password" class="form-control" formControlName="password" placeholder="סיסמא">
        </label>
        <div class="color-red mb-1" *ngIf="formSubmitted && !registerForm.controls.password.valid">נא להזין סיסמא</div>
      </div>
      <div class="text-center">
        <button class="btn btn-submit" type="submit" [disabled]="requestInProgress">הצטרפות</button>
        <div class="alert" [class.color-red]="error" [class.color-turquoise]="!error" *ngIf="serverMessage">{{serverMessage}}</div>
      </div>
    </form>
    <!--<div class="font-size-21 mt-4">או</div>
    <div class="font-weight-500 font-size-21">הרשמה דרך</div>
    <div class="mt-2">
      <img src="assets/facebook_blue.svg" alt="אייקון של פייסבוק" (click)="registerWithFacebook()">
      <img class="mr-4" src="assets/google.svg" alt="אייקון של גוגל" (click)="registerWithGoogle()">
    </div>-->
    <div class="mt-4 d-flex justify-content-center align-items-center">
      <div class="d-flex">
        <input type="checkbox" id="acceptTerms" [(ngModel)]="acceptTerms">
        <label class="form-check-label" for="acceptTerms"></label>
      </div>
      <div class="d-flex mr-3 font-weight-500 font-size-17 agree-text">
        <a routerLink="/terms">אני מסכים/ה לתנאי ההצטרפות</a>
      </div>
    </div>
    <div class="color-red mb-1" *ngIf="formSubmitted && !acceptTerms">נא לאשר את תנאי ההצטרפות</div>
  </div>
</ng-template>
