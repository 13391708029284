import {Component, Inject, OnInit} from '@angular/core';
import {AuthService} from './auth/auth.service';
import {FestivalService} from './shared/services/festival.service';
import {DOCUMENT} from '@angular/common';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  title = 'app';

  constructor(
    @Inject(DOCUMENT) private document: any,
    private route: ActivatedRoute,
    private authService: AuthService,
    private festivalService: FestivalService
  ) {
  }

  ngOnInit(): void {
    this.authService.autoLogin();
    this.festivalService.setFestivalFromURL();
  }

}
