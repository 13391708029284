<div id="desktop-menu" class="container d-flex justify-content-center">
  <div class="row">
    <div *ngIf="user" class="p-3 user-box">
      <span>שלום </span>
      <span>{{user.name}}</span>
      <div *ngIf="user" class="logout col d-flex align-items-center ml-2" (click)="logout()">
        <span class="ml-2 pointer">יציאה</span>
        <fa-icon [icon]="faSignOutAlt" size="2x"></fa-icon>
      </div>
    </div>
    <div *ngIf="user && festival" class="p-3">
      <a class="pointer" href="https://my.hanadiv.org/festival/{{festival.festivalEnglishName}}/my-area" target="_blank">לאיזור האישי</a>
    </div>
    <div *ngIf="festival">
      <div class="p-3 pointer" routerLink="/about" *ngIf="festival.about">אודות</div>
      <div class="p-3 pointer" routerLink="/navigation" *ngIf="festival.navigation">הוראות הגעה</div>
      <div class="p-3 pointer" routerLink="/team" *ngIf="festival.team">צוות</div>
      <div class="p-3 pointer" routerLink="/thanks" *ngIf="festival.thanks">תודות</div>
      <div class="p-3 pointer" routerLink="/sleep" *ngIf="festival.sleep">הוראות לינה</div>
      <div class="p-3 pointer" routerLink="/updates" *ngIf="festival.updates">עדכונים</div>
    </div>
    <div *ngIf="user" class="p-3 pointer" routerLink="/my-events-list">האירועים שלי</div>
    <div class="p-3 pointer" routerLink="/all-events-list">כל האירועים</div>
    <div class="p-3 pointer" *ngFor="let item of festivalDays" routerLink="/events-list/{{item.date}}">{{item.day}}</div>
    <div *ngIf="!user" class="navbar-login-link p-3 pointer" (click)="closeNavbar(); openLoginModal()">התחברות</div>
  </div>
</div>
