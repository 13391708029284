import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {DataService} from '../shared/services/data.service';
import {FormControl, FormGroup} from '@angular/forms';
import {Category} from '../shared/models/category.model';

@Component({
  selector: 'app-categories-select',
  templateUrl: './categories-select.component.html',
  styleUrls: ['./categories-select.component.css']
})
export class CategoriesSelectComponent implements OnInit, OnDestroy {

  selectForm: FormGroup;
  categories: Category[];
  categoriesSubscription: Subscription;
  selectedCategory = '';

  constructor(
    private dataService: DataService
  ) {
  }

  ngOnInit(): void {
    this.categoriesSubscription = this.dataService.getGenres().subscribe(categories => {
      this.categories = categories;
    });

    this.selectForm = new FormGroup({
      category: new FormControl('')
    });
  }

  onSelect(): void {
    const selectedCategory = this.categories.find(item => parseInt(item.genreID, 10) === parseInt(this.selectForm.controls.category.value.genreID, 10));
    this.dataService.categorySubject.next(selectedCategory);
  }

  ngOnDestroy(): void {
    this.categoriesSubscription.unsubscribe();
  }
}
