<div class="container-fluid mb-5">
  <app-navbar></app-navbar>
  <app-day-title title="תוצאות חיפוש"></app-day-title>

  <div *ngIf="this.search" class="filter-item">{{search}}</div>
  <div *ngIf="this.date" class="filter-item">{{date}}</div>

  <app-categories-select></app-categories-select>

  <div *ngFor="let item of events">
    <app-event-box [event]="item"></app-event-box>
  </div>
</div>
<app-footer-navbar *ngIf="events"></app-footer-navbar>
