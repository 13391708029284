<div *ngIf="festival">
  <img id="navbar-menu" src="assets/Icons_Menu_Black.svg" alt="" (click)="closeNavbar()"
       [attr.aria-expanded]="!isCollapsed" aria-controls="mobile-menu">
</div>

<div id="mobile-menu" class="container" [ngbCollapse]="isCollapsed">
  <div class="row mt-4">
    <div class="col d-flex mr-2">
      <fa-icon [icon]="faTimesCircle" size="2x" (click)="closeNavbar()"></fa-icon>
    </div>
    <div *ngIf="user" class="col d-flex align-items-center justify-content-end ml-2" (click)="logout()">
      <span class="ml-2">יציאה</span>
      <fa-icon [icon]="faSignOutAlt" size="2x"></fa-icon>
    </div>
  </div>
  <div *ngIf="user" class="text-center">
    <span>שלום </span>
    <span>{{user.name}}</span>
  </div>
  <div class="mt-3">
    <form [formGroup]="searchForm" (ngSubmit)="onSearch()">
      <label class="w-100">
        <input type="text" class="form-control search-festival" formControlName="search" placeholder="חיפוש אירוע">
      </label>
    </form>
  </div>
  <div *ngIf="user && festival" class="navbar-link p-3" (click)="closeNavbar()">
    <a class="color-primary" href="https://my.hanadiv.org/festival/{{festival.festivalEnglishName}}/my-area" target="_blank">לאיזור האישי</a>
  </div>
  <div *ngIf="festival">
    <div class="navbar-link p-3" routerLink="/about" (click)="closeNavbar()" *ngIf="festival.about">אודות</div>
    <div class="navbar-link p-3" routerLink="/navigation" (click)="closeNavbar()" *ngIf="festival.navigation">הוראות הגעה</div>
    <div class="navbar-link p-3" routerLink="/team" (click)="closeNavbar()" *ngIf="festival.team">צוות</div>
    <div class="navbar-link p-3" routerLink="/thanks" (click)="closeNavbar()" *ngIf="festival.thanks">תודות</div>
    <div class="navbar-link p-3" routerLink="/sleep" (click)="closeNavbar()" *ngIf="festival.sleep">הוראות לינה</div>
    <div class="navbar-link p-3" routerLink="/updates" (click)="closeNavbar()" *ngIf="festival.updates">עדכונים</div>
  </div>
  <div *ngIf="user" class="navbar-link p-3" routerLink="/my-events-list" (click)="closeNavbar()">האירועים שלי</div>
  <div class="navbar-link p-3" routerLink="/all-events-list" (click)="closeNavbar()">כל האירועים</div>
  <div class="navbar-link">
    <div *ngFor="let item of festivalDays" routerLink="/events-list/{{item.date}}" (click)="closeNavbar()" class="p-2">{{item.day}}</div>
  </div>
  <div *ngIf="!user" class="navbar-login-link p-3" (click)="closeNavbar(); openLoginModal()">התחברות</div>
</div>
