import {Component, Input, OnInit} from '@angular/core';
import {Event} from '../shared/models/event.model';
import * as moment from 'moment';
import {Router} from '@angular/router';

@Component({
  selector: 'app-event-box',
  templateUrl: './event-box.component.html',
  styleUrls: [
    '../shared/styles/list.css',
    './event-box.component.css'
  ]
})
export class EventBoxComponent implements OnInit {

  @Input() event: Event;
  formattedDate;
  formattedHour;

  constructor(private router: Router) {
  }

  ngOnInit(): void {
    if (this.event) {
      this.formattedDate = moment(this.event.date).format('DD/MM');
      this.formattedHour = moment(this.event.hour, 'h:mm:ss A').format('HH:mm');
    }
  }

  onNavigateToEventPage(eventID): void {
    // Save the clicked eventID or scroll position
    localStorage.setItem('lastClickedEventID', eventID);

    // Navigate to the event-page
    this.router.navigate(['/event-page', eventID]);
  }
}
