import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {Router} from '@angular/router';

@Component({
  selector: 'app-search-festival',
  templateUrl: './search-festival.component.html',
  styleUrls: ['./search-festival.component.css']
})
export class SearchFestivalComponent implements OnInit {

  searchForm: FormGroup;

  constructor(private router: Router) {
  }

  ngOnInit(): void {
    this.searchForm = new FormGroup({
      search: new FormControl('')
    });
  }

  onSearch(): void {
    const search = this.searchForm.controls.search.value;
    this.router.navigate([`/search-results`, {search}]);
  }

}
