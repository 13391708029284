import {Component, OnDestroy, OnInit} from '@angular/core';
import {Festival} from '../shared/models/festival.model';
import {Event} from '../shared/models/event.model';
import {Subscription} from 'rxjs';
import {FestivalService} from '../shared/services/festival.service';

@Component({
  selector: 'app-footer-navbar',
  templateUrl: './footer-navbar.component.html',
  styleUrls: ['./footer-navbar.component.css']
})
export class FooterNavbarComponent implements OnInit, OnDestroy {

  festival: Festival;
  closestEvent: Event;
  festivalSubscription: Subscription;

  constructor(private festivalService: FestivalService) {
  }

  ngOnInit(): void {
    this.festivalSubscription = this.festivalService.festivalsSubject.subscribe(festival => {
        if (festival) {
          this.festival = festival;
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.festivalSubscription.unsubscribe();
  }

}
