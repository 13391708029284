<div class="event-box mt-4 text-center pointer" (click)="onNavigateToEventPage(event.eventID)">
  <div class="p-3 text-right">
    <div class="event-title font-size-18">{{event.title}}</div>
    <div class="event-initiative-owner font-size-16">{{event.initiativeOwnerFirstName}} {{event.initiativeOwnerLastName}}</div>
  </div>
  <div>
    <div class="event-image" [style.backgroundImage]="'url('+ event.path +')'">
      <div class="event-genre mt-2 ml-2">{{event.genre}}</div>
    </div>
  </div>
  <div class="d-flex justify-content-between content-wrapper">
    <div class="d-flex align-items-center">
      <img class="p-1" src="assets/np_calendar.svg" alt="calendar icon">
      <div>{{event.date | date: 'd/M' }}</div>
    </div>
    <div class="d-flex align-items-center">
      <img class="p-1" src="assets/clock.svg" alt="clock icon">
      <div>
        <span>{{formattedHour}}</span>
      </div>
    </div>
    <div class="d-flex align-items-center">
      <img class="p-1" src="assets/user.svg" alt="user icon">
      <div *ngIf="!event.virtual">
        <span>{{event.attendees.length <= event.totalNumberOfPeopleThatCanAttend ? event.attendees.length : event.totalNumberOfPeopleThatCanAttend}}</span>
        <span>/</span>
        <span>{{event.totalNumberOfPeopleThatCanAttend}}</span>
        <span> נרשמו</span>
      </div>
    </div>
  </div>
  <div class="d-flex content-wrapper">
    <div class="d-flex align-items-center pl-3 pr-1">
      <img src="assets/location.svg" alt="location icon">
    </div>
    <div class="event-description-wrapper">
      <div>{{event.placeDescription}}</div>
      <div>
        רחוב
        {{event.street}}
        {{event.houseNumber}}
        כניסה
        {{event.entrance}}
        {{event.city}}
      </div>
    </div>
  </div>
  <div class="d-flex content-wrapper">
    <div class="event-initiative-description">{{event.initiativeDescription}}</div>
  </div>
  <div class="event-link-wrapper">
    <div class="d-flex justify-content-end pt-4 pb-3 pl-3">
      <div class="event-link font-size-17 font-weight-500">לצפייה באירוע</div>
    </div>
  </div>
</div>
